.google-sign-in-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 16px;
    background-color: #db4437;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.google-sign-in-button:hover {
    background-color: #c53929;
}

.google-icon {
    margin-right: 8px;
    
}

.google-sign-in-button span {
    font-weight: bold;
}